/* Dashboard */

.transaparent-tab-border {
  border-bottom: 1px solid $nav-tabs-border-color
  }
  .graph-custom-legend {
    ul {
      display: flex;
      align-items: center;
      float: right;
      li {
        display: flex;
        align-items: center;
        list-style: none;
        margin-left: 1rem;
        .legend-box {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          margin-right: 10px;
          display: inline-block;
        }
        &:first-child {
          margin-left: 0px;
        }
      }
      @media (max-width: 991px) {
        float: left;
        padding-left: 0;
      }
    }
    &.primary-dot {
      ul {
        li {
          .legend-box {
            background: $primary;
          }
        }
      }
    }
    
  }
  .card-danger-gradient {
    background: $card-mixed-danger;
  }
  .recent-activity {
    .activity-info {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      text-align: center;
      color: $white;
      padding-top: 5px;
      font-weight: bold;
      margin-right: 1.25rem;
      position: relative;
      &:after {
        content: '';
        width: 2px;
        height: 54px;
        position: absolute;
        left: 50%;
        top: 33px;
        border-right: 1px dashed $border-color;
      }
      &.hide-border {
        &:after {
          display: none;
        }
  
      }
    }
  }
  
  .rtl {
    .graph-custom-legend {
      ul {
        float: left;
        padding-left: 0;
        li {
          margin-left: 0;
          margin-right: 1rem;
          .legend-box {
            margin-right: 0;
            margin-left: .625rem;
          }
        }
      }
    }
    .activity-info {
      margin-left: 1.25rem;
      margin-right: 0;
    }
  }
  
  .dotted-border {
    border: 1px dashed $border-color;
  }
  .legend-horizontal {
    display: flex;
    padding: 0;
    li {
      list-style: none;
      font-size: .875rem;
      color: $dark;
      .legend-dots {
        width: .75rem;
        height: .75rem;
        border-radius: 100%;
        display: inline-block;
        margin-right: .5rem;
        margin-left: .5rem;
      }
    }
  }
  .CircularProgressbar {
    .CircularProgressbar-path {
      stroke-width: 5px;
    }
    .CircularProgressbar-trail {
      stroke: #eee;
      stroke-width: 5px;
    }
    .CircularProgressbar-background {
      fill: green;
    }
  }
  .CircularProgressbar {
    &.progress-order {
      .CircularProgressbar-path {
        stroke: url(#progress-order);
      }
    }
  }
  .CircularProgressbar {
    &.progress-visitors {
      .CircularProgressbar-path {
        stroke: url(#progress-visitors);
      }
    }
  }
  .CircularProgressbar {
    &.progress-impressions {
      .CircularProgressbar-path {
        stroke: url(#progress-impressions);
      }
    }
  }
  .CircularProgressbar {
    &.progress-followers {
      .CircularProgressbar-path {
        stroke: url(#progress-followers);
      }
    }
  }
  .conversion-border {
    border-right: 1px solid $border-color;
    .rtl & {
      border-right: none;
      border-left: 1px solid $border-color;
    }
    @media (max-width: 1024px) { 
      border:none;
      .rtl & {
        border:none;
      }
    }
  }

  .proBanner {
    .purchase-popup {
      @extend .grid-margin;
      background: #fff;
      padding: 15px 20px;
      @include border-radius(3px);
  
      .btn {
        margin-right: 20px;
        font-weight: 500;
        color: $white;
        @include border-radius(5px);
        @include transition-duration(0.2s);
  
        &.download-button {
          background: rgba(249, 249, 249, 0.7);
          color: #969292;
          border: 1px solid darken(#e4e4e4,5%);
        }
  
        &.purchase-button {
          background-color: $info;
          color: $white;
          border: none;
          line-height: 1;
          vertical-align: middle;
        }
      }
  
      p {
        margin-bottom: auto;
        margin-top: auto;
        color: darken(#e4e4e4,40%);
        font-weight: 400;
        vertical-align: middle;
        line-height: 1;
      }
  
      i {
        vertical-align: middle;
        line-height: 1;
        margin: auto 0;
        color: darken(#e4e4e4,20%);
      }
      .bannerClose {
        cursor: pointer;
      }
    }
    &.hide {
      display: none;
    }
  }

  .card_body{
    padding: 1rem !important;
    gap: 1rem !important;
  }
  .grey_color{
    color :#5b626b;
  }
  .dashboard-card {
    a{
      transition: 0.2s ease-in-out;
    }
    transition: 0.2s ease-in-out;
  }
  .dashboard-card:hover{
    a{
      color: white !important;
      background-color: orange;
      text-decoration: none;
    }
    background: #fff;
    border-radius: 5px;
    transform: translate(5px, -5px);
    box-shadow: rgba(255, 172, 28, 0.199) -6px 6px, rgba(255, 172, 28, 0.19) -12px 12px ;
  }