.auth {
	height: 100vh;
	background-image: url("../../../images/auth/login-bg.jpg");
	background-size: cover;

	.login-half-bg {
		background: url("../../../images/auth/login-bg.jpg");
		background-size: cover;
	}

	.register-half-bg {
		background: url("../../../images/auth/register-bg.jpg");
		background-size: cover;
	}

	&.lock-full-bg {
		background: url("../../../images/auth/lockscreen-bg.jpg");
		background-size: cover;
	}

	.lock-profile-img {
		width: 90px;
		height: 90px;
		border-radius: 100%;
	}

	.auth-form-light {
		background: $white;

		select {
			color: $input-placeholder-color;
		}

		.input-group {
			.form-control {

				&:focus,
				&:active {
					border-color: $border-color;
				}
			}
		}
	}

	.auth-form-transparent {
		background: transparent;

		.form-control,
		.input-group-text {
			border-color: $secondary;

			&:focus,
			&:active {
				border-color: $secondary;
			}
		}

		select {
			outline-color: $secondary;
		}
	}

	&.auth-img-bg {
		padding: 0;

		.auth-form-transparent {
			@media (min-width:768px) {
				width: 55%;
				margin: auto;
			}
		}
	}

	.brand-logo {
		margin-bottom: 2rem;

		img {
			width: 150px;
		}
	}

	form {
		.form-group {
			margin-bottom: 1.5rem;

			label {
				font-size: .8125rem;
			}

			.form-control {
				background: transparent;
				border-radius: 0;
				font-size: .9375rem;
			}
		}

		.auth-form-btn {
			// padding: 1rem 3rem;
			width: 90%;
			line-height: 1.5;

			&:hover {
				background-color: #F59D0E !important;
			}
		}

		.auth-link {
			font-size: $default-font-size;

			&:hover {
				color: initial;
			}
		}
	}
}

.auth-input-field {
	position: relative;
	height: 50px;
	width: 100%;

	input {
		position: absolute;
		height: 100%;
		width: 100%;
		padding: 0 35px;
		border: none;
		outline: none;
		font-size: 16px;
		border-bottom: 2px solid #ccc;
		border-top: 2px solid transparent;
		transition: border-bottom-color 0.4s ease;

		&:is(:focus, :valid) {
			border-bottom-color: #F59D0E;
		}
	}

	i {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: #F59D0E;
		font-size: 23px;
		transition: color 0.4s ease;

		.icon {
			left: 0;
		}
	}

	.showHidePw {
		right: 0;
		cursor: pointer;
		padding: 10px;
	}
}

.account-form-center {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
}

.checkbox-content {
	display: flex;
	align-items: center;

	.checkbox-text {
		display: flex;
		justify-content: space-between;
	}

	input {
		margin: 0 8px -2px 4px;
		accent-color: #F59D0E;
	}
}

.forgot-pwd {
	color: #F59D0E;
	text-decoration: none;
	font-weight: 600;

	&:hover {
		color: #F59D0E;
		text-decoration: underline;
	}
}


.auth-form-btn {
	width: 100%;
	outline: none;
	border: none;
	background-color: #F59D0E;
	color: #fff;
	font-size: small;
	border-radius: 5px;

	span {
		display: inline-block;
		position: relative;
		transition: 0.5s;

		&:after {
			content: "\00bb";
			position: absolute;
			opacity: 0;
			top: 0;
			right: -20px;
			transition: 0.5s;
		}
	}

	&:after {
		content: ' \00A7';
		color: white;
		position: absolute;
		opacity: 0;
		top: 0;
		right: -20px;
		transition: 0.5s;
	}

	&:hover {
		content: "\00bb";
		color: white;
		padding-right: 25px;
		cursor: pointer;

		&:after {
			opacity: 1;
			right: 0;
		}

		span {
			padding-right: 25px;

			&:after {
				opacity: 1;
				right: 0;
			}
		}
	}
}

.bad-credentials {
	/* 2 animations: shake, and glow red */
	animation-name: shake, glow-red;
	animation-duration: 0.7s, 0.35s;
	animation-iteration-count: 1, 2;
}

@keyframes shake {

	0%,
	20%,
	40%,
	60%,
	80% {
		transform: translateX(8px);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translateX(-8px);
	}
}

@keyframes glow-red {
	50% {
		border-color: indianred;
	}
}