.spinner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #eee;
  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(#979fd0, 0.3);
    border-top-color: #979fd0;
    animation: 1.5s spin infinite linear;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-overlay {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading {
  background: transparent;
  display: flex;
  justify-content: space-between;
  max-width: 72px;
  margin: 30px auto;
  width: 100%;
}
.animated-dot {
  animation: loading 1s cubic-bezier(0.55, -0.77, 0.42, 1.79) 0s infinite normal
    both running;
  background: #f59d0e;
  border-radius: 50%;
  display: block;
  height: 1rem;
  width: 1rem;
  margin-right: 10px;
}
.animated-dot:nth-child(2) {
  animation-name: loading-2;
}
.animated-dot:nth-child(3) {
  animation-name: loading-3;
}
@keyframes loading {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(22%);
  }
  25% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0);
  }
}
@keyframes loading-2 {
  25% {
    transform: translateY(0);
  }
  29% {
    transform: translateY(22%);
  }
  50% {
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(0);
  }
}
@keyframes loading-3 {
  50% {
    transform: translateY(0);
  }
  54% {
    transform: translateY(22%);
  }
  75% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
